import { defineStore } from 'pinia'
import axios from 'axios'
import useProjectsStore from './useProjectsStore'

export default defineStore('auth', () => {
  const projectsStore = useProjectsStore()

  async function login (login: string, password: string) {
    const res = await axios.post('/get_token/', {
      username: login,
      password
    })

    localStorage.setItem('token', res.data.token)
  }

  function logout () {
    localStorage.removeItem('token')
  }

  async function fetchProjects () {
    try {
      await projectsStore.fetchProjects()
    } catch (e) {
    }
  }

  return {
    login,
    logout,
    fetchProjects
  }
})
